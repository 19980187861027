@import '../../variables';

/**
  * IMPORTANT!
  * Each variable MUST have !default flag for correct production build
  */

$theme-name: $FANEXPOHQ !default;

//////////////////////////////////////////////////
//
// Tenant variables
//
//////////////////////////////////////////////////

$tenant-primary-color: #002244 !default;
$tenant-secondary-color: #525a5c !default;
$tenant-accent-color: #cc31c2 !default;
$tenant-border-separator-color: #979797 !default;
$tenant-search-text-color: #333333 !default;

$TENANT-HEADER-HEIGHT: 60px !default;

//////////////////////////////////////////////////
//
// Variables
//
//////////////////////////////////////////////////

$font-family-serif: 'Barlow', serif !default;
$font-family-sans-serif: 'Barlow', sans-serif !default;

$font-family-base: $font-family-sans-serif !default;
$font-size-base: 16px !default;
$font-weight-base: 400 !default;
$line-height-base: 1.4 !default;

$font-family-button: 'Barlow Condensed Extra Bold', sans-serif;

$body-bg: #efefef !default;
$body-color: #333333 !default;

$link-color: $tenant-accent-color !default;
$link-decoration: none !default;
$link-hover-color: darken($tenant-accent-color, 15%) !default;
$link-hover-decoration: underline !default;

$form-border-color: #dee0e5 !default;
$form-disabled-color: #f1f2f4 !default;
$form-helper-color: #666666 !default;
$form-error-color: #c9474d !default;
$form-light-error-color: #ffe1e3 !default;
$form-success-color: #31b03f !default;

//////////////////////////////////////////////////
//
// Multi Level Navigation
//
//////////////////////////////////////////////////

$multi-level-nav-level-1-height: 70px !default;
$multi-level-nav-level-1-height-mobile: 70px !default;
$multi-level-nav-level-2-height: 50px !default;
$multi-level-nav-underline-width: 1px !default;

$multi-level-nav-base-bg-color: #fff !default;
$multi-level-nav-animation-duration: 0.4s !default;
$multi-level-nav-animation-delay: 0.4s !default;

$multi-level-nav-level-1-bg-color: #000 !default;
$multi-level-nav-level-1-text-color: #fff !default;
$multi-level-nav-level-2-bg-color: #e6e6e6 !default;
$multi-level-nav-level-2-text-color: #000 !default;
$multi-level-nav-btn-active-color: null !default;
